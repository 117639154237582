export const simpleMapper = ({ data }) => {
  return data;
};

export const locationsMapper = (data) => {
  return data.map((loc) => {
    loc['searchIndex'] = `${loc.name}${loc.address_line_1}${loc.address_line_2}${loc.city}${loc.state}${loc.zip}${loc.phone_number}`.toLowerCase();
    return loc;
  });
};
