const fontFamily = '"Noto Sans", Arial, Helvetica, sans-serif';

export default {
  textStyles: {
    heroTitle: {
      tag: 'h2',
      fontSize: [14, 15, 15, , 16],
      lineHeight: 'normal',
      fontFamily,
      color: 'heroText',
      fontWeight: 500,
    },
    smallTitle: {
      tag: 'h2',
      fontSize: [11, 12, 12, , 13],
      fontFamily,
      fontWeight: 500,
    },
    extraSmallTitle: {
      tag: 'h3',
      fontSize: [8, 9, 9, , 10],
      fontFamily,
      color: 'smallText',
      fontWeight: 500,
    },
    section: {
      tag: 'p',
      fontSize: [5, 6, 6, , 7],
      fontFamily,
      color: 'sectionText',
    },
    bodycopy: {
      tag: 'p',
      fontSize: [2, 3, 3, , 4],
      lineHeight: [1.6, 1.36, 1.6],
      fontFamily,
      color: 'smallText',
    },
    microText: {
      tag: 'span',
      fontSize: [1, 1, 1, , 1],
      fontFamily,
      color: 'smallText',
    },
  },
  boxStyles: {
    defaultBox: {
      tag: 'div',
      position: 'relative',
      display: 'block',
    },
    flexBox: {
      tag: 'div',
      position: 'relative',
      display: 'flex',
    },
    flexColumnBox: {
      tag: 'div',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    sectionWrapperBox: {
      tag: 'div',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    centerBox: {
      tag: 'div',
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sectionTextBox: {
      tag: 'div',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
    },
    sectionImageBox: {
      tag: 'div',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'column',
    },
    gridBox: {
      tag: 'div',
      display: 'grid',
      position: 'relative',
    },
  },
  buttonStyles: {
    textButton: {
      tag: 'button',
      display: 'flex',
      alignItems: 'center',
      flexShrink: '0',
      border: '0',
      minHeight: '44px',
      minWidth: '44px',
    },
  },
  breakpoints: ['768px', '1025px', '1280px', '1920px'],
  fontSizes: [
    14,
    16
  ],
  colors: {
    background: '#fff',
    text: '#2C436E',
    smallText: '#99A3B5',
  },
  fonts: {
    gravitonium: fontFamily,
  },
};
