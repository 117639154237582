import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import MapContainer from './domains/map/containers/MapContainer';
import Header from 'components/Header';
import { Provider } from 'react-redux';
import Routes from './routes';
import store from './store';
import theme from './theme/theme';
import { ThemeProvider } from 'styled-components';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Header />
      <MapContainer />
      <Routes />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
