const prefix = 'locations';

export const FETCH_LOCATIONS = `${prefix}/FETCH_LOCATIONS`;
export const fetchLocations = () => ({ type: FETCH_LOCATIONS });

export const SEARCH_LOCATIONS = `${prefix}/SEARCH_LOCATIONS`;
export const searchLocations = (query) => ({ type: SEARCH_LOCATIONS, payload: query });

export const LOCATIONS_LOADED = `${prefix}/LOCATIONS_LOADED`;
export const locationsLoaded = (data) => ({ type: LOCATIONS_LOADED, payload: data });

export const SELECT_LOCATION = `${prefix}/SELECT_LOCATION`;
export const selectLocation = (id) => ({ type: SELECT_LOCATION, payload: id });

export const CHECK_IN = `${prefix}/CHECK_IN`;
export const checkIn = (id) => ({ type: CHECK_IN, payload: id });

export const CREATE_CUSTOMER = `${prefix}/CREATE_CUSTOMER`;
export const createCustomer = (data) => ({ type: CREATE_CUSTOMER, payload: data });

export const CREATE_CUSTOMER_ERROR = `${prefix}/CREATE_CUSTOMER_ERROR`;
export const createCustomerError = (data) => ({ type: CREATE_CUSTOMER_ERROR, payload: data });

export const CUSTOMER_CREATED = `${prefix}/CUSTOMER_CREATED`;
export const customerCreated = (data) => ({ type: CUSTOMER_CREATED, payload: data });

export const UPDATE_CUSTOMER_STATUS = `${prefix}/UPDATE_CUSTOMER_STATUS`;
export const updateCustomerStatus = (id) => ({ type: UPDATE_CUSTOMER_STATUS, payload: id });

export const CUSTOMER_STATUS_UPDATED = `${prefix}/CUSTOMER_STATUS_UPDATED`;
export const customerStatusUpdated = (data) => ({ type: CUSTOMER_STATUS_UPDATED, payload: data });

export const SET_LOCATIONS_SEARCH_QUERY = `${prefix}/SET_LOCATIONS_SEARCH_QUERY`;
export const setLocationsSearchQuery = (query) => ({ type: SET_LOCATIONS_SEARCH_QUERY, payload: query });

export const SET_CURRENT_POSITION = `${prefix}/SET_CURRENT_POSITION`;
export const setCurrentPosition = (lat, lng) => ({ type: SET_CURRENT_POSITION, payload: {lat: lat, lng: lng} });
