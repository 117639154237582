import * as mappers from './mappers';

const API_BASE = 'https://safewait.net/api';

/**
 * A function to build headers for the request
 * Is needed for the token insertion
 */
const headers = (downloadHeaders = false) => ({
  ...(downloadHeaders ? {} : {
    'Accept': 'application/json',
  }),
  'Content-Type': 'application/json',
});

/**
 * A function to treat ruby's errors in response
 * and transform them to the Promise.reject call
 */
const handleResponse = (response) => {
  if (response.status === 500) {
    alert('Backend has reported a cache problem, the data may be invalid, consider reloading page and wait for a while. Apologies for inconvenience.');
  }

  return response.text().then((text) => {
    const incorrectStatus = response.status !== 200;
    let data = null;
    try {
      data = JSON.parse(text);
    } catch (e) {
      data = null;
    }
    // const data = (text && !incorrectStatus) ?  : null;
    const hasErrors = (data && (data.errors || data.error));
    const error = hasErrors ? (data.errors ? data.errors : data.error) : {};

    // console.log({ hasErrors, text, incorrectStatus, error })

    return (hasErrors || incorrectStatus) ? Promise.reject(error) : Promise.resolve(data);
  });
};

/**
 * Axios wrapping methods
 */
const get = (url) => fetch(`${API_BASE}${url}`, { method: 'GET', headers: headers() }).then(handleResponse);
const post = (url, data) => fetch(`${API_BASE}${url}`, { method: 'POST', headers: headers(), body: JSON.stringify(data) }).then(handleResponse);
const put = (url, data) => fetch(`${API_BASE}${url}`, { method: 'PUT', headers: headers(), body: JSON.stringify(data) }).then(handleResponse);
const patch = (url, data) => fetch(`${API_BASE}${url}`, { method: 'PATCH', headers: headers(), body: JSON.stringify(data) }).then(handleResponse);
const remove = (url) => fetch(`${API_BASE}${url}`, { method: 'DELETE', headers: headers() }).then(handleResponse);

/**
 * The API interface
 */
const api = {
  locations: {
    getAll: () => get('/locations').then(mappers.locationsMapper),
    search: (query) =>
      get(
        `/locations?query=${query}`
      ).then(mappers.locationsMapper),
  },
  customers: {
    create: (customer) => post(`/customers`, {customer}),
    getStatus: (id) => get(`/customers/${id}`),
  }
};

export default api;
