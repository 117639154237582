import * as actions from '../actions';

const INITIAL_STATE = {
  position: { lat: 40.7478536, lng: -73.9863142 },
  locations: [],
  customer: null,
  query: '',
  createCustomerErrors: null,
  selectedLocationId: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.LOCATIONS_LOADED:
      return { ...state, locations: action.payload };

    case actions.SELECT_LOCATION:
      return { ...state, selectedLocationId: action.payload };

    case actions.CREATE_CUSTOMER:
      return { ...state, customer: null, createCustomerErrors: null };

    case actions.CREATE_CUSTOMER_ERROR:
      return { ...state, createCustomerErrors: action.payload };

    case actions.CUSTOMER_CREATED:
      return { ...state, customer: action.payload };

    case actions.CUSTOMER_STATUS_UPDATED:
      return { ...state, customer: { ...state.customer, ...action.payload } };

    case actions.SET_LOCATIONS_SEARCH_QUERY:
      return { ...state, query: action.payload };

    case actions.SET_CURRENT_POSITION:
      return { ...state, position: action.payload };

    default:
      return state;
  }
};
