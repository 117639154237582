import * as actions from '../actions';
import * as selectors from '../selectors';
import api from 'libs/api';
import history from 'libs/history';

const fetchLocations = (store, action) => {
  if (action.type === actions.FETCH_LOCATIONS) {
    api.locations.getAll().then((response) => {
      store.dispatch(actions.locationsLoaded(response));
    }).catch((error) => {
      console.error(error);
    });
  }
};

const searchLocations = (store, action) => {
  if (action.type === actions.SEARCH_LOCATIONS) {
    api.locations.search(action.payload).then((response) => {
      store.dispatch(actions.locationsLoaded(response));
    }).catch((error) => {
      console.error(error);
    });
  }
};

const createLocation = (store, action) => {
  if (action.type === actions.CREATE_CUSTOMER) {
    api.customers.create(action.payload).then((response) => {
      store.dispatch(actions.customerCreated(response));
      history.push(`/customers/${response.id}`);
    }).catch((error) => {
      console.error(error);
      store.dispatch(actions.createCustomerError(error));
    });
  }
};

const selectLocation = (store, action) => {
  if (action.type === actions.SELECT_LOCATION) {
    history.push('/checkin');
  }
};

const updateCustomerStatus = (store, action) => {
  if (action.type === actions.UPDATE_CUSTOMER_STATUS) {
    api.customers.getStatus(action.payload).then((response) => {
      store.dispatch(actions.customerStatusUpdated(response));
    }).catch((error) => {
      console.error(error);
    });
  }
};

export default [
  fetchLocations,
  searchLocations,
  selectLocation,
  createLocation,
  updateCustomerStatus,
];
