import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoSvg } from 'assets/images/icons_small-logo.svg';
import history from 'libs/history';

const StickyHeaderWrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 47px;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  padding-top: 16px;
`;

const Header = ({ }) => {
  return (
    <StickyHeaderWrapper onClick={() => { history.push('/') }}>
      <LogoSvg />
    </StickyHeaderWrapper>
  );
};

export default Header;
