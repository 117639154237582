import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import store from './store';
import history from 'libs/history';

const LocationsPage = lazy(() => import('domains/locations/pages/LocationsPage'));
const LocationCheckInPage = lazy(() => import('domains/locations/pages/LocationCheckInPage'));
const CustomerPage = lazy(() => import('domains/locations/pages/CustomerPage'));

class Protected extends React.PureComponent {
  componentDidMount () {
    this.unsubscribe = store.subscribe(() => this.forceUpdate());
  }

  componentWillUnmount () {
    this.unsubscribe();
  }

  render () {
    return this.props.children;
  }
};

export default (props) => (
  <Suspense fallback={<h1>Loading...</h1>}>
    <Router history={history}>
      <Switch>
        <Route exact path='/' render={(props) => <Protected><LocationsPage {...props} /></Protected> } />
        <Route exact path='/checkin' render={(props) => <Protected><LocationCheckInPage {...props} /></Protected> } />
        <Route exact path='/customers/:id' render={(props) => <Protected><CustomerPage {...props} /></Protected>} />
      </Switch>
    </Router>
  </Suspense>
);
