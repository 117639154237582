import React from 'react';
import { connect } from 'react-redux';

import GoogleMapView from 'domains/map/components/GoogleMapView'
import * as actions from 'domains/locations/actions';
import * as selectors from 'domains/locations/selectors';

class MapContainer extends React.PureComponent {
  render () {
    const { fetchLocations, locations, selectedLocation, selectLocation, position, setCurrentPosition } = this.props;

    return (
      <GoogleMapView
        selectLocation={selectLocation}
        fetchLocations={fetchLocations}
        locations={locations}
        selectedLocation={selectedLocation}
        position={position}
        setCurrentPosition={setCurrentPosition}
      />
    );
  }
};

const mapStateToProps = state => ({
  selectedLocation: selectors.getSelectedLocation(state),
  locations: selectors.getLocations(state),
  position: selectors.getPosition(state),
});

const mapDispatchToProps = dispatch => ({
  fetchLocations: () => dispatch(actions.fetchLocations()),
  selectLocation: (id) => dispatch(actions.selectLocation(id)),
  setCurrentPosition: (lat, lng) => dispatch(actions.setCurrentPosition(lat, lng)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
