import { createSelector } from 'reselect';
import every from 'lodash/every';
import sortBy from 'lodash/sortBy';
import slice from 'lodash/slice';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import { distance } from 'libs/utils';

export const getState = state => state.locations;
export const getLocations = createSelector(getState, state => state.locations);
export const getQuery = createSelector(getState, state => state.query);
export const getCustomer = createSelector(getState, state => state.customer);
export const getPosition = createSelector(getState, state => state.position);
export const getCreateCustomerErrors = createSelector(getState, state => state.createCustomerErrors);
export const getSelectedLocationId = createSelector(getState, state => state.selectedLocationId);

export const getSelectedLocation = createSelector(getLocations, getSelectedLocationId, (locations, selected_id) =>
  selected_id !== null
    ? locations.find((loc) => loc.id === selected_id)
    : null
);

export const getFilteredLocations = createSelector(getLocations, getQuery, (locations, query) => {
  if (query.length > 0) {
    const parts = query.toLowerCase().split(/\s+/);

    return locations.filter((loc) =>
      every(parts, (p) => { return loc.searchIndex.indexOf(p) > -1 })
    )
  } else {
    return [];
  }
});

export const getNearestLocations = createSelector(getLocations, getPosition, (locations, position) => {
  const sortFunc = (loc) => {
    return distance(position.lat, position.lng, loc.lat, loc.lng);
  }
  const filterFunc = (loc) => {
    return distance(position.lat, position.lng, loc.lat, loc.lng) < 10;
  }
  
  return slice(sortBy(filter(locations, filterFunc), [sortFunc]), 0, 5);
});
