import { createStore, applyMiddleware, compose } from 'redux';

import effects from './effects';
import reducer from './reducer';

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

export default createStore(reducer, composeEnhancers(applyMiddleware(...effects.map(e => s => n => (a) => ( n(a), e(s, a) )))));
