import React from 'react';
import forEach from 'lodash/forEach';

// import buildPool from './pool';

const google = window.google;
// const GOOGLE_MAPS_API_KEY = 'AIzaSyC2VulKk_MYY7ORqR0MZQXn4308D99RwAM';
const ZOOM_LEVEL = 16;

// let geocoder = null;
// const geocoderPool = buildPool(1, 600); /* One request per 600ms */
// const geocode = (address) => {
//   if (geocoder === null) {
//     geocoder = new google.maps.Geocoder();
//   }
//
//   return new Promise((resolve, reject) => geocoder.geocode({ address }, (results, status) => status === 'OK' ? resolve(results) : reject(status)))
// };

// let coordinatesCache = {};
// let failedRequestsCache = {};
// const resolveCoordinates = (location) => new Promise((resolve, reject) => {
//   if (coordinatesCache[location.id]) {
//     resolve(coordinatesCache[location.id]);
//   } else if (failedRequestsCache[location.id]) {
//     reject('Address was not resolved previously. Skipping this attempt.');
//   } else {
//     const address = [location.address_line_1, location.address_line_2, location.city, location.state, location.zip, 'USA'].filter((t) => !!t).join(', ');
//     geocoderPool.push(() => geocode(address)).then((results) => {
//       coordinatesCache[location.id] = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
//       resolve(coordinatesCache[location.id]);
//     }).catch((status) => {
//       failedRequestsCache[location.id] = true;
//       reject(`Address ${address} was not resolved. Status: ${status}.`);
//     });
//   }
// });

let markers = {};

export default class GoogleMapView extends React.PureComponent {
  assignRootRef = (node) => this.rootRef = node;
  state = { loading: false, error: null };

  createMap = () => {
    const { fetchLocations, position, setCurrentPosition } = this.props;

    this.setState({ loading: true, error: null });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentPosition(position.coords.latitude, position.coords.longitude)
        // console.log("Latitude is :", position.coords.latitude);
        // console.log("Longitude is :", position.coords.longitude);
        this.map = new google.maps.Map(this.rootRef, { zoom: ZOOM_LEVEL, center: { lat: position.coords.latitude, lng: position.coords.longitude }, mapTypeId: 'roadmap' });
        fetchLocations();
      },
      (error) => {
        console.log(error);
        this.map = new google.maps.Map(this.rootRef, { zoom: ZOOM_LEVEL, center: { lat: position.lat, lng: position.lng }, mapTypeId: 'roadmap' });
        fetchLocations();
      }
    );
    // if(location) {
    //   resolveCoordinates(location).then((coords) => {
    //     const { lat, lng } = coords;
    //
    //     this.map = new google.maps.Map(this.rootRef, { zoom: ZOOM_LEVEL, center: { lng, lat }, mapTypeId: 'roadmap' });
    //     this.marker = new google.maps.Marker({ position: { lng, lat }, map: this.map, title: location.name });
    //
    //     this.setState({ loading: false });
    //   }).catch((error) => {
    //     this.setState({ error: 'Invalid Position', loading: false });
    //   });
    // } else {
    //   this.map = new google.maps.Map(this.rootRef, { zoom: ZOOM_LEVEL, center: { lat: 40.7478536, lng: -73.9863142 }, mapTypeId: 'roadmap' });
    // }
  };

  updateMap = () => {
    const { selectLocation, selectedLocation, locations } = this.props;

    // this.setState({ loading: true, error: null });
    // resolveCoordinates(location).then((coords) => {
      // const { lat: lat, lng: lng } = coords;

      if(selectedLocation) {
        this.map.setCenter({ lat: parseFloat(selectedLocation.lat), lng: parseFloat(selectedLocation.lng) });
      }
      forEach(locations, (location)=> {
        if(!markers[location.id]) {
          markers[location.id] = new google.maps.Marker({ position: { lng: parseFloat(location.lng), lat: parseFloat(location.lat) }, map: this.map, title: location.name });
          markers[location.id].addListener("click", () => {
            selectLocation(location.id)
          });
        }
      });
      // if(this.marker) {
      //   this.marker.setPosition(new google.maps.LatLng({lat: selectedLocation.lat, lng: selectedLocation.lng}));
      // } else {
      //   this.marker = new google.maps.Marker({ position: { lng: selectedLocation.lng, lat: selectedLocation.lat }, map: this.map, title: location.name });
      // }
      // this.setState({ loading: false });
    // }).catch((error) => {
    //   this.setState({ error: 'Invalid Position', loading: false });
    // });
  };

  componentDidMount () {
    this.createMap();
  }

  componentDidUpdate (prevProps) {
    if (
      (!!prevProps.selectedLocation !== !!this.props.selectedLocation) ||
      (prevProps.selectedLocation && prevProps.selectedLocation.id !== this.props.selectedLocation.id) ||
      (prevProps.locations.length !== this.props.locations.length)
    ) {
      if (this.map) {
        this.updateMap();
      } else {
        this.createMap();
      }
    }
  }

  render () {
    return (
      <div style={{ width: '100vw', height: '100vh' }} ref={this.assignRootRef} />
    );
  }
};
